import { Box, Container, Typography } from "@mui/material"
import { Helmet } from "react-helmet"

const AvisoPrivacidad = () => {
    return(
        <Box>
            <Helmet>
                <title>Aviso de Privacidad | Casas Pecsa App</title>
                <meta name="description" content="Conozca cómo recopilamos, utilizamos y protegemos su información personal en nuestra aplicación. Nuestro aviso de privacidad detalla el uso de datos, sus derechos como usuario y nuestras medidas de seguridad para garantizar la protección de su información." />
            </Helmet>
            <Container>
                <Typography sx={{ mt:12}}>
                    Aviso Privacidad
                </Typography>
            </Container>
        </Box>
    )
}

export { AvisoPrivacidad }